import React, { useEffect, useState } from 'react';
import './App.css';
const io = require('socket.io-client');
const socket = io('https://lang-backend.now.sh');

function App() {

  const [news, setNews] = useState({});

  const [question, setQuestion] = useState({});

  useEffect(() => {
    socket.on('news', data => {
      setNews(data);
    });

    socket.on('newQuestion', data => {
      setQuestion(data);
    });
  }, []);

  const newQuestion = () => {
    socket.emit('requestQuestion');
  }

  return (
    <div>
      <p>Lang game</p>
      <p>News: {JSON.stringify(news)}</p>
      <p>Question: {JSON.stringify(question)}</p>
      <button onClick={newQuestion}>New Question</button>
    </div>
  );
}

export default App;
